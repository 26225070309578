// react
import React, { useEffect, useState } from "react";
// feature
import step from "../../features/step/step";
// form
import { useForm } from 'react-hook-form';
import { CheckOtpCode } from "../../services/client/auth/checkOtp";
import { ResendOtp } from "../../services/client/auth/resendOtp";
// react query
import { useMutation } from "@tanstack/react-query";
// toast
import { toastOptions } from "../../features/toast/toast";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// context
import { useModal } from "../../context/modalContext";
// icons
import { IconClock, IconSend } from "@tabler/icons-react";
// @ts-ignore
import Cookies from "js-cookie";
// error 
import { onError } from "../../features/error/error";
import { SendCart } from "../../services/panel/cart/sendCart";

const CheckOtp = () => {
    const { register, handleSubmit }: any = useForm<any>();
    const { isModalOpen } = useModal();
    const [disabled, setDisabled] = useState(false);
    const [timeLeft, setTimeLeft] = useState<number | null>(null);
    const Cartmutation = useMutation({
        mutationFn: SendCart,
        onSuccess: () => {
            localStorage.setItem('cart', '');
            window.open('/user/profile', '_self');
        },
        onError: () => {
            window.open('/user/profile', '_self');
        },
    });
    const mutation = useMutation({
        mutationFn: CheckOtpCode,
        onSuccess: data => {
            toast.success('با موفقیت وارد شدید', toastOptions);
            // @ts-ignore
            let items = JSON.parse(localStorage.getItem('cart'))
            const simplifiedArray = items.map((item: { product_id: any; variation_id: any; qty: any; }) => ({
                product_id: item.product_id,
                variation_id: item.variation_id,
                qtybutton: item.qty,
            }));
            let dataNew: any = {
                items: simplifiedArray,
            }
            localStorage.setItem('cart', '');
            Cartmutation.mutate(dataNew)
        },
        onError: () => {
            toast.error('دوباره تلاش کنید...', toastOptions)
        },
    });
    const ResendMutation = useMutation({
        mutationFn: ResendOtp,
        onSuccess: data => {
            toast.success('کد با موفقیت ارسال شد', toastOptions);
            Cookies.set('agent_token', data.token);
            timer()
        },
        onError: () => {
            toast.error('دوباره تلاش کنید...', toastOptions)
        },
    });
    const onSubmit = (data: any) => {
        let array = data.text;
        let otpValue = array[4] + array[3] + array[2] + array[1];
        let value: any = {
            otp: otpValue,
        }
        mutation.mutate(value);
    };
    const Resend = () => {
        let data: any = {}
        ResendMutation.mutate(data);
    }
    const timer = () => {
        setDisabled(true);
        setTimeLeft(60);
        const interval = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev !== null && prev > 1) {
                    return prev - 1;
                } else {
                    clearInterval(interval);
                    setDisabled(false);
                    return null;
                }
            });
        }, 1000);
        return () => clearInterval(interval);
    }
    useEffect(() => {
        if (isModalOpen) {
            timer();
        }
    }, [isModalOpen])
    return (
        <>
            <section className={`modal fade`} id='otp'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content rounded rounded-4'>
                        <div className='modal-body'>
                            <div className='text-center w-100'>
                                <img alt='logo' className='brand-logo acband-price-icon' src={require('./../../assets/media/logo/final.png')} />
                            </div>
                            <div className="content p-md-4 p-2 pt-1">
                                <div className="texts text-center">
                                    <p className="h3 fw-bold text-center">ورود و ثبت نام</p>
                                    <p className="text mt-3 text-center">
                                        کد ارسال شده را وارد نمایید
                                        {/* @ts-ignore */}
                                        <span className="ms-2 fw-bold text-site" data-bs-target="#agent" data-bs-toggle="modal">(بازگشت)</span>
                                    </p>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit, onError)}>
                                    <div className="row justify-content-center mb-3">
                                        {Array.from({ length: 4 }).map((_, index) => (
                                            <div key={index + "form"} className="col-2">
                                                <input
                                                    onKeyDown={(e) => step(e, index, `digit`)}
                                                    maxLength={1}
                                                    id={`digit${index}`}
                                                    className="form-control text-center ltr"
                                                    {...register(`text.${index + 1}`, { required: 'یکی از فیلدای کد رو فراموش کردی' })}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <button disabled={mutation.isPending} className="btn btn-outline-primary w-100 mt-2">
                                        {mutation.isPending ? 'در حال ارسال' : 'تایید'}
                                    </button>
                                    {isModalOpen ?
                                        <button onClick={() => Resend()} type="button" disabled={disabled} className="border-0 bg-white mt-3">
                                            <span className="mt-3 font-medium-lg text-site">
                                                {timeLeft !== null ? <IconClock size={14} /> : <IconSend size={14} />}
                                                <span className="ms-1">
                                                    {timeLeft}
                                                </span>
                                                <span className="ms-1">
                                                    {timeLeft !== null ? 'ثانیه تا ارسال دوباره' : 'ارسال دوباره'}
                                                </span>
                                            </span>
                                        </button>
                                        :
                                        null
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CheckOtp;